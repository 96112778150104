<template>
  <section v-if="viewDateTime && newDatetime" class="datetime-picker" v-class-mod:datetime-picker="currentMode">
    <h2 v-if="title" class="datetime-picker__title">{{ title }}</h2>
    <date-time-switch
      :value="currentMode"
      class="datetime-picker__mode-switch"
      @update="(m: Mode) => (currentMode = m)"
    />
    <div class="datetime-picker__sections">
      <section class="datetime-picker__date">
        <header class="datetime-picker__header">
          <date-banner
            :view-date="viewDateTime"
            :type="'month'"
            class="datetime-picker__month-banner"
            @update="(d: DateTime) => (viewDateTime = d)"
          />
        </header>
        <section class="datetime-picker__date-selector">
          <week-days :view-date="viewDateTime" class="datetime-picker__week-days" />
          <date-grid
            :view-date="viewDateTime"
            :selected-date="newDatetime"
            :min="min"
            :max="max"
            select="date"
            class="datetime-picker__date-grid"
            @update:date="
              (date: DateTime) => {
                newDatetime = newDatetime.set('year', date.get('year'));
                newDatetime = newDatetime.set('month', date.get('month'));
                newDatetime = newDatetime.set('date', date.get('date'));
                currentMode = 'time';
                selectedTimeDial = 'hours';
                dialSelectorElem?.setFocus();
              }
            "
            ref="gridElem"
          />
        </section>
      </section>
      <section class="datetime-picker__time">
        <header class="datetime-picker__header">
          <time-banner
            :time="newDatetime"
            :dial="selectedTimeDial"
            @update="(dial: Dial) => (selectedTimeDial = dial)"
          />
        </header>

        <section class="datetime-picker__time-selector">
          <dial-selector
            :time="newDatetime"
            :dial="selectedTimeDial"
            :min="min"
            :max="max"
            @update:date="(date: DateTime) => (newDatetime = date)"
            @update:dial="(dial: Dial) => (selectedTimeDial = dial)"
            @update="
              () => {
                if (selectedTimeDial === 'minutes') {
                  submitBtn?.$el.focus();
                }
              }
            "
            ref="dialSelectorElem"
          />
        </section>
      </section>
    </div>

    <span class="datetime-picker__seperator" />

    <div class="datetime-picker__cta-buttons">
      <cta-button
        :action="() => $emit('close')"
        :title="translations.datetime.cancel"
        :theme="'transparent'"
        size="large"
        class="datetime-picker__cta-button"
      />
      <cta-button
        :action="update"
        :disabled="!canSave"
        :title="translations.datetime.save"
        :theme="'green'"
        :stylingOptions="{ disabledBg: 'green-200', disabledColor: 'black-20' }"
        class="datetime-picker__cta-button"
        size="large"
        ref="submitBtn"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { DateTime, getTime } from "@horizon56/time";

import { translations } from "../../../infrastructure/translations";
import CtaButton from "../../buttons/cta-button.vue";
import DateBanner from "../date-picker/components/date-banner.vue";
import DateGrid from "../date-picker/components/date-grid.vue";
import WeekDays from "../date-picker/components/week-days.vue";
import DialSelector from "../time-picker/dial-selector.vue";
import { Dial } from "../time-picker/dial-selector.vue";
import TimeBanner from "../time-picker/time-banner.vue";
import DateTimeSwitch from "./components/date-time-switch.vue";

export type Mode = "time" | "date";

const props = defineProps<{
  title?: string;
  datetime?: DateTime;
  max?: DateTime;
  min?: DateTime;
  canClear?: boolean;
  stickTo?: HTMLElement;
}>();

const emit = defineEmits<{
  (e: "update", a: DateTime): void;
  (e: "close"): void;
}>();

const isOpen = ref(false);

const submitBtn = ref<InstanceType<typeof CtaButton>>();
const gridElem = ref<InstanceType<typeof DateGrid>>();
const dialSelectorElem = ref<InstanceType<typeof DialSelector>>();

const viewDateTime = ref(props.datetime ?? getTime(true));
const newDatetime = ref(props.datetime ?? getTime(true));

const currentMode = ref<Mode>("date");
const selectedTimeDial = ref<Dial>("hours");

const canSave = computed(() => {
  if (!newDatetime.value) {
    return false;
  }
  if (props.datetime && +newDatetime.value === +props.datetime) {
    return false;
  }
  if (props.min && +newDatetime.value < +props.min) {
    return false;
  }
  if (props.max && +newDatetime.value > +props.max) {
    return false;
  }
  return true;
});

const update = async () => {
  if (!canSave.value || !newDatetime.value) {
    return;
  }

  emit("update", newDatetime.value);
  emit("close");

  isOpen.value = false;
};

watch(
  () => props.datetime,
  () => {
    if (props.datetime) {
      viewDateTime.value = props.datetime;
      newDatetime.value = props.datetime;
    }
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
.datetime-picker {
  $block: &;
  $card-width: 310px;
  width: $card-width;
  overflow-x: hidden;
  background: var(--menu-bg);
  border-radius: var(--app-radius-medium);
  border: 1px solid var(--black-10);
  width: $card-width * 2;
  max-height: 100%;
  @include set-scrollbar;
  &__title {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--black-10);
    height: var(--app-input-label-height);
    background: var(--menu-hover);
  }

  &__sections {
    display: flex;
    flex-flow: row nowrap;
    width: $card-width * 2;
    background: var(--menu-bg);
    transition: margin-left var(--app-transition);
  }

  &__date,
  &__time {
    width: $card-width;
  }

  &__date {
    border-right: 1px solid var(--black-10);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-shrink: 0;
    height: var(--app-input-label-height);
    background: var(--menu-hover);
  }

  &__input {
    display: flex;
    flex-shrink: 0;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 12px;
    width: 100%;
    height: auto;
  }

  &__week-days,
  &__date-grid,
  &__month-banner {
    width: 100%;
  }

  &__date-grid {
    margin-bottom: 8px;
  }

  &__date-selector {
    width: calc(100% - 24px);
    margin: 12px auto;
  }

  &__time-selector {
    width: calc(100% - 40px);
    margin: 20px auto;
  }

  &__trigger-button {
    flex-shrink: 0;
    align-self: center;
    margin: 12px auto;
  }

  &__seperator {
    width: 100%;
    display: flex;
    border-top: 1px solid var(--black-10);
  }

  &__warning {
    display: inline-flex;
    margin: 12px auto 0;
    font-size: var(--app-font-size-title-medium);
    background: var(--yellow-vivid);
    color: var(--static-black-90);
    padding: 4px 8px;
    border-radius: var(--app-radius-small);

    @include icon-before($icon-info) {
      margin-right: 4px;
    }
  }

  &__cta-buttons {
    display: flex;
    flex-flow: row nowrap;
    padding: 12px;
    margin-left: auto;
    width: 100%;
    align-items: flex-end;
  }

  &__cta-button {
    &:first-child {
      margin-left: auto;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__explanation {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 66px;
    color: var(--black-50);
  }

  &__mode-switch {
    display: none;
  }

  @media (max-width: #{$card-width * 2 + 20px}) {
    &__mode-switch {
      display: flex;
      border-bottom: 1px solid var(--black-10);
    }

    width: $card-width;

    &--time #{$block}__sections {
      margin-left: $card-width * -1;
    }
  }
}
</style>
